<template>
  <div class="fill-height py-3 py-md-10">
    <v-container>
      <div v-html="privacy_policy"></div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "PrivacyPolicy",
  computed: {
    ...mapState({
      privacy_policy: state => state.Organization.privacy_policy
    })
  },
  created() {
    this.$store.dispatch('Organization/onGetPrivacyPolicy');
  }
}
</script>

<style scoped>

</style>
